import { Layout, DatePicker, Space, Table, Card, Button, Col, Row, Typography, Result } from "antd"
import dayjs from "dayjs"
import { presets } from "../utils/dateRangePreset"
import PropTypes from "prop-types"
import { useNavigate } from "react-router-dom"
import React, { useEffect, useState } from "react"
import { API } from "../App"
import { CSVLink } from "react-csv"
import { DownloadOutlined } from "@ant-design/icons"
import { AvgFlavorChart, FlavorOppsChart, ScreenLayout, Top5Widget, HierarchySelector } from "../components"
import { useLocation } from "react-router-dom"

const { Text } = Typography
const { Header } = Layout
const { RangePicker } = DatePicker

const Dashboard = (props) => {
	const { search } = useLocation()
	const s = new URLSearchParams(search)
	let { dateRangeEnd, dateRangeStart, customerName } = Object.fromEntries(s)
	const navigate = useNavigate()

	if (!dateRangeStart) dateRangeStart = dayjs().subtract(1, "week")
	if (!dateRangeEnd) dateRangeEnd = dayjs()

	let initialHierarchy = customerName ? { hierarchyLevel1: customerName } : {}
	let initialHierarchyLevel = customerName ? 1 : 0

	const [filteredStores, setFilteredStores] = useState([])
	const [filteredDateRange, setFilteredDateRange] = useState(
		dateRangeStart && dateRangeEnd ? [dayjs(dateRangeStart), dayjs(dateRangeEnd)] : null
	)
	const [storeCount, setStoreCount] = useState(0)
	const [opportunities, setOpportunities] = useState(0)
	const [flavorings, setFlavorings] = useState(0)
	const [performance, setPerformance] = useState(0)
	const [tableData, setTableData] = useState([])
	const [chartData, setChartData] = useState([])
	const [csvData, setCSVData] = useState([])
	const [topDrugs, setTopDrugs] = useState([])
	const [topDrugPercent, setTopDrugPercent] = useState(0)
	const [topFlavors, setTopFlavors] = useState([])
	const [topFlavorPercent, setTopFlavorPercent] = useState(0)
	const [performanceData, setPerformanceData] = useState([])

	const [loading, setLoading] = useState(true)

	const [hierarchyLevel, setHierarchyLevel] = useState(initialHierarchyLevel)
	const [tableDataHierarchy, setTableDataHierarchy] = useState([])
	const [hierarchyName, setHierarchyName] = useState("")
	const [storeData, setStoreData] = useState()
	// Get the stores for the selected customer
	useEffect(() => {
		if (customerName) {
			API.getFilteredStores({ where: { hierarchyLevel1: { eq: customerName } } }).then((res) => {
				setStoreData(res.data)
				API.getFilteredSnapshots({ where: { hierarchyLevel1: { eq: customerName } } }).then((res) =>
					setFilteredStores(res.data)
				)
			})
		}
		if (dateRangeStart && dateRangeEnd) {
			setFilteredDateRange([dayjs(dateRangeStart), dayjs(dateRangeEnd)])
		}
	}, [])

	useEffect(() => {
		// Calculate performance data (to display in the widget on the left)
		if (storeCount > 0) {
			let days = filteredDateRange[1].diff(filteredDateRange[0], "days") + 1
			let opportunitiesPerDay = 0
			let flavoringsPerDay = 0

			if (flavorings > 0) {
				flavoringsPerDay = (flavorings / days / storeCount).toFixed(1)
			}
			if (opportunities > 0) {
				opportunitiesPerDay = (opportunities / days / storeCount).toFixed(1)
			}

			if (flavorings > 0 || opportunities > 0) {
				let data = [
					{ label: "Stores", value: storeCount },
					{ label: "Opportunities", value: opportunities },
					{ label: "Opps/Day/Store", value: opportunitiesPerDay },
					{ label: "Flavorings", value: flavorings },
					{ label: "Flavorings/Day/Store", value: flavoringsPerDay },
				]
				setPerformanceData(data)
			}
		}
	}, [storeCount, flavorings, opportunities, filteredDateRange])

	// Define columns for log table
	const columns = [
		{
			title: "Title",
			dataIndex: "title",
			key: "title",
			render: (title, record) => {
				if (!record.children) {
					return (
						<Button
							type="link"
							onClick={() => {
								let selectedStore = storeData.find((d) => d.store_name === record.title)
								navigate(
									`/store/?storeID=${
										selectedStore.id
									}&dateRangeStart=${filteredDateRange[0].format()}&dateRangeEnd=${filteredDateRange[1].format()}`
								)
							}}
						>
							{title}
						</Button>
					)
				} else return title
			},
		},
		{
			title: "Stores",
			dataIndex: "stores",
			key: "stores",
		},
		{
			title: "Recon",
			dataIndex: "recon",
			key: "recon",
			sorter: (a, b) => a.recon - b.recon,
			render: (text) => text.toLocaleString(),
		},
		{
			title: "Recon/Flavor",
			dataIndex: "reconFlavor",
			key: "reconFlavor",
			sorter: (a, b) => a.reconFlavor - b.reconFlavor,
			render: (text) => text.toLocaleString(),
		},
		{
			title: "Flavor",
			dataIndex: "flavor",
			key: "flavor",
			sorter: (a, b) => a.flavor - b.flavor,
			render: (text) => text.toLocaleString(),
		},
		{
			title: "Opps",
			dataIndex: "opportunities",
			key: "opportunities",
			sorter: (a, b) => a.opportunities - b.opportunities,
			render: (text) => text.toLocaleString(),
		},
		{
			title: "Opps/Day/Store",
			dataIndex: "oppsPerStore",
			key: "oppsPerStore",
			sorter: (a, b) => a.oppsPerStore - b.oppsPerStore,
			render: (text) => text.toLocaleString(),
		},
		{
			title: "Flavorings",
			dataIndex: "flavorings",
			key: "flavorings",
			sorter: (a, b) => a.flavorings - b.flavorings,
			render: (text) => text.toLocaleString(),
		},
		{
			title: "Flavorings/Day/Store",
			dataIndex: "flavoringsPerStore",
			key: "flavoringsPerStore",
			sorter: (a, b) => a.flavoringsPerStore - b.flavoringsPerStore,
			render: (text) => text.toLocaleString(),
		},
		{
			title: "Flavoring %",
			dataIndex: "performance",
			key: "performance",
			sorter: (a, b) => a.performance - b.performance,
			render: (text) => <Text strong>{text}</Text>,
		},
	]
	// Generates data structure to display in the log table
	const generateTableData = (storeObjects, filteredStoreObjects, hierarchy = "") => {
		let opportunityCountTotal = 0
		let flavoringCountTotal = 0
		let data = []
		let csvRowData = []
		let allDrugs = {}
		let allFlavors = {}

		let rangeSpan = "day"
		if (filteredDateRange[0].isSame(dayjs().startOf("year"), "d") && filteredDateRange[1].isSame(dayjs(), "d"))
			rangeSpan = "year"
		if (
			filteredDateRange[0].isSame(dayjs().startOf("month").subtract(1, "month"), "d") &&
			filteredDateRange[1].isSame(dayjs().startOf("month").subtract(1, "day"), "d")
		)
			rangeSpan = "month"
		if (filteredDateRange[0].isSame(dayjs().startOf("month"), "d") && filteredDateRange[1].isSame(dayjs(), "d"))
			rangeSpan = "month"
		const x = dayjs(filteredDateRange[1])

		storeObjects.forEach((hierarchyObj) => {
			let storeObjs = []

			if (hierarchyObj.level === "hierarchyLevelStore") {
				const tempHierarchy = hierarchy.length > 0 ? hierarchy?.split("|") : []
				for (let i = 6; i > tempHierarchy.length; i--) {
					tempHierarchy.push("")
				}
				try {
					storeObjs = storeData.filter((a) => a.id === hierarchyObj.storeId)
					if (storeObjs.length === 0) return
					storeObjs.forEach((store) => {
						let opportunityCount = 0
						let flavoringCount = 0
						let reconCount = 0
						let flavorCount = 0
						let reconFlavorCount = 0

						for (
							let m = dayjs(filteredDateRange[1]);
							m.isSame(filteredDateRange[0]) || m.isAfter(filteredDateRange[0]);
							m = m.subtract(1, rangeSpan)
						) {
							let dayKey = m.format("MM/DD/YYYY")
							if (rangeSpan === "month") dayKey = m.format("MM/YYYY")
							if (rangeSpan === "year") dayKey = m.format("YYYY")

							let dayObj = store["condensed_log"][rangeSpan][dayKey]

							if (dayObj) {
								opportunityCount += dayObj.opportunities

								flavoringCount += dayObj.flavorings
								reconCount += dayObj.recons_dispense
								flavorCount += dayObj.flavor_dispense
								reconFlavorCount += dayObj.recons_and_flavor_dispense
								for (const [key, value] of Object.entries(dayObj.drugs)) {
									if (allDrugs[key]) allDrugs[key] += value
									else allDrugs[key] = value
								}
								for (const [key, value] of Object.entries(dayObj.flavors)) {
									if (allFlavors[key]) allFlavors[key] += value
									else allFlavors[key] = value
								}
							}

							opportunityCountTotal += opportunityCount
							flavoringCountTotal += flavoringCount
						}

						let performance = ((flavoringCount / opportunityCount) * 100).toFixed(2) + "%"

						let _stores = 1
						const days = filteredDateRange[1].diff(filteredDateRange[0], "days") + 1

						if (opportunityCount > 0 || flavoringCount > 0) {
							let dataRow = {
								key: store.id,
								title: store.store_name,
								_stores: _stores,
								recon: reconCount,
								flavor: flavorCount,
								reconFlavor: reconFlavorCount,
								opportunities: opportunityCount,
								flavorings: flavoringCount,
								oppsPerStore: (opportunityCount / days / storeObjs.length).toFixed(1),
								flavoringsPerStore: (flavoringCount / days / storeObjs.length).toFixed(1),
								performance,
							}

							let csvDataRow = { ...dataRow }
							delete csvDataRow.key

							data.push(dataRow)
							csvRowData.push(csvDataRow)
						}
					})
				} catch (err) {
					console.log(err)
					return
				}
			} else {
				let tempHierarchy = hierarchyObj.hierarchy.length > 0 ? hierarchyObj.hierarchy?.split("|") : []
				for (let i = 6; i > tempHierarchy.length; i--) {
					tempHierarchy.push("")
				}
				storeObjs = filteredStoreObjects.filter(
					(a) =>
						a.hierarchyLevel1 === tempHierarchy[0] &&
						a.hierarchyLevel2 === tempHierarchy[1] &&
						a.hierarchyLevel3 === tempHierarchy[2] &&
						a.hierarchyLevel4 === tempHierarchy[3] &&
						a.hierarchyLevel5 === tempHierarchy[4]
				)
				switch (hierarchyObj.level) {
					case "hierarchyLevel1":
						storeObjs = filteredStoreObjects.filter((d) => d.hierarchyLevel1 === hierarchyObj.value)
						break
					case "hierarchyLevel2":
						storeObjs = filteredStoreObjects.filter(
							(d) => d.hierarchyLevel1 == tempHierarchy[0] && d.hierarchyLevel2 === hierarchyObj.value
						)
						break
					case "hierarchyLevel3":
						storeObjs = filteredStoreObjects.filter(
							(d) =>
								d.hierarchyLevel1 == tempHierarchy[0] &&
								d.hierarchyLevel2 == tempHierarchy[1] &&
								d.hierarchyLevel3 === hierarchyObj.value
						)
						break
					case "hierarchyLevel4":
						storeObjs = filteredStoreObjects.filter(
							(d) =>
								d.hierarchyLevel1 == tempHierarchy[0] &&
								d.hierarchyLevel2 == tempHierarchy[1] &&
								d.hierarchyLevel3 == tempHierarchy[2] &&
								d.hierarchyLevel4 === hierarchyObj.value
						)
						break
					case "hierarchyLevel5":
						storeObjs = filteredStoreObjects.filter(
							(d) =>
								d.hierarchyLevel1 == tempHierarchy[0] &&
								d.hierarchyLevel2 == tempHierarchy[1] &&
								d.hierarchyLevel3 == tempHierarchy[2] &&
								d.hierarchyLevel4 == tempHierarchy[3] &&
								d.hierarchyLevel5 === hierarchyObj.value
						)
						break
				}

				if (storeObjs.length === 0) return
				let opportunityCount = 0
				let flavoringCount = 0
				let reconCount = 0
				let flavorCount = 0
				let reconFlavorCount = 0

				for (
					let m = dayjs(filteredDateRange[1]);
					m.isSame(filteredDateRange[0]) || m.isAfter(filteredDateRange[0]);
					m = m.subtract(1, rangeSpan)
				) {
					let dayKey = m.format("MM/DD/YYYY")
					if (rangeSpan === "month") dayKey = m.format("MM/YYYY")
					if (rangeSpan === "year") dayKey = m.format("YYYY")

					try {
						opportunityCount += storeObjs
							.map((d) => {
								let dayObj = d["condensed_log"][rangeSpan][dayKey]
								return dayObj ? dayObj.opportunities : 0
							})
							.reduce((a, b) => a + b)
					} catch (err) {}

					flavoringCount += storeObjs
						.map((d) => {
							let dayObj = d["condensed_log"][rangeSpan][dayKey]
							return dayObj ? dayObj.flavorings : 0
						})
						.reduce((a, b) => a + b)
					reconCount += storeObjs
						.map((d) => {
							let dayObj = d["condensed_log"][rangeSpan][dayKey]
							return dayObj ? dayObj.recons_dispense : 0
						})
						.reduce((a, b) => a + b)
					flavorCount += storeObjs
						.map((d) => {
							let dayObj = d["condensed_log"][rangeSpan][dayKey]
							return dayObj ? dayObj.flavor_dispense : 0
						})
						.reduce((a, b) => a + b)
					reconFlavorCount += storeObjs
						.map((d) => {
							let dayObj = d["condensed_log"][rangeSpan][dayKey]
							return dayObj ? dayObj.recons_and_flavor_dispense : 0
						})
						.reduce((a, b) => a + b)

					storeObjs.forEach((d) => {
						let dayObj = d["condensed_log"][rangeSpan][dayKey]

						if (dayObj) {
							for (const [key, value] of Object.entries(dayObj.drugs)) {
								if (allDrugs[key]) allDrugs[key] += value
								else allDrugs[key] = value
							}
							for (const [key, value] of Object.entries(dayObj.flavors)) {
								if (allFlavors[key]) allFlavors[key] += value
								else allFlavors[key] = value
							}
						}
					})

					opportunityCountTotal += opportunityCount
					flavoringCountTotal += flavoringCount
				}

				let performance = ((flavoringCount / opportunityCount) * 100).toFixed(2) + "%"

				let stores = 0

				if (opportunityCount > 0 || flavoringCount > 0) {
					let children = []
					if (hierarchyObj.children) {
						children = generateTableData(hierarchyObj.children, filteredStoreObjects, hierarchyObj.hierarchy).data
					}
					children.forEach((element) => {
						if (element._stores) stores += element._stores
						else {
							stores += element.stores
						}
					})

					const days = filteredDateRange[1].diff(filteredDateRange[0], "days") + 1
					let dataRow = {
						key: `${hierarchyObj.hierarchy}|${hierarchyObj.value}`,
						title: hierarchyObj.value,
						stores,
						recon: reconCount,
						flavor: flavorCount,
						reconFlavor: reconFlavorCount,
						opportunities: opportunityCount,
						flavorings: flavoringCount,
						oppsPerStore: (opportunityCount / days / stores).toFixed(1),
						flavoringsPerStore: (flavoringCount / days / stores).toFixed(1),
						performance,
					}
					if (hierarchyObj.children) {
						dataRow.children = children
					}

					let csvDataRow = { ...dataRow }
					delete csvDataRow.key

					data.push(dataRow)
					csvRowData.push(csvDataRow)
				}
			}
		})

		return { data, csvRowData }
	}

	// Generates data on averages for use in charts
	const generateAverageData = (storeObjects) => {
		let opportunityCountTotal = 0
		let flavoringCountTotal = 0
		let data = []
		let csvRowData = []
		let allDrugs = {}
		let allFlavors = {}

		let rangeSpan = getRangeSpan()

		for (
			let m = dayjs(filteredDateRange[0]);
			m.isSame(filteredDateRange[1]) || m.isBefore(filteredDateRange[1]);
			m = m.add(1, rangeSpan)
		) {
			let dayKey = m.format("MM/DD/YYYY")
			if (rangeSpan === "month") dayKey = m.format("MM/YYYY")
			if (rangeSpan === "year") dayKey = m.format("YYYY")

			let opportunityCount = storeObjects
				.map((d) => {
					let dayObj = d["condensed_log"][rangeSpan][dayKey]
					return dayObj ? dayObj.opportunities : 0
				})
				.reduce((a, b) => a + b)
			let flavoringCount = storeObjects
				.map((d) => {
					let dayObj = d["condensed_log"][rangeSpan][dayKey]
					return dayObj ? dayObj.flavorings : 0
				})
				.reduce((a, b) => a + b)
			let reconCount = storeObjects
				.map((d) => {
					let dayObj = d["condensed_log"][rangeSpan][dayKey]
					return dayObj ? dayObj.recons_dispense : 0
				})
				.reduce((a, b) => a + b)
			let flavorCount = storeObjects
				.map((d) => {
					let dayObj = d["condensed_log"][rangeSpan][dayKey]
					return dayObj ? dayObj.flavor_dispense : 0
				})
				.reduce((a, b) => a + b)
			let reconFlavorCount = storeObjects
				.map((d) => {
					let dayObj = d["condensed_log"][rangeSpan][dayKey]
					return dayObj ? dayObj.recons_and_flavor_dispense : 0
				})
				.reduce((a, b) => a + b)

			storeObjects.forEach((d) => {
				let dayObj = d["condensed_log"][rangeSpan][dayKey]

				if (dayObj) {
					for (const [key, value] of Object.entries(dayObj.drugs)) {
						if (allDrugs[key]) allDrugs[key] += value
						else allDrugs[key] = value
					}
					for (const [key, value] of Object.entries(dayObj.flavors)) {
						if (allFlavors[key]) allFlavors[key] += value
						else allFlavors[key] = value
					}
				}
			})

			opportunityCountTotal += opportunityCount
			flavoringCountTotal += flavoringCount

			let performance = ((flavoringCount / opportunityCount) * 100).toFixed(2)
			const days = dayjs(filteredDateRange[1]).diff(dayjs(filteredDateRange[0]), "day") + 1

			if (opportunityCount > 0 || flavoringCount > 0) {
				let dataRow = {
					key: dayKey,
					date: dayKey,
					recon: reconCount,
					flavor: flavorCount,
					reconFlavor: reconFlavorCount,
					opportunities: opportunityCount,
					flavorings: flavoringCount,
					oppsPerStore: (opportunityCount / days / filteredStores.length).toFixed(1),
					flavoringsPerStore: (flavoringCount / days / filteredStores.length).toFixed(1),
					performance,
				}

				data.push(dataRow)
			}
		}

		let drugs = Object.entries(allDrugs)
			.map(([key, value]) => ({ label: key, value }))
			.sort((a, b) => b.value - a.value)
			.slice(0, 5)
		let flavors = Object.entries(allFlavors)
			.map(([key, value]) => ({ label: key, value }))
			.sort((a, b) => b.value - a.value)
			.slice(0, 5)

		setTopDrugs(drugs)
		setTopFlavors(flavors)

		if (drugs.length > 0) {
			setTopDrugPercent(parseInt((drugs[0].value / opportunityCountTotal) * 100))
		}
		if (flavors.length > 0) {
			setTopFlavorPercent(parseInt((flavors[0].value / flavoringCountTotal) * 100))
		}

		return { opportunityCountTotal, flavoringCountTotal, data, csvRowData }
	}

	// Monitor filter changes to regenerate data
	useEffect(() => {
		if (
			!filteredStores ||
			!filteredDateRange ||
			hierarchyLevel === 0 ||
			filteredStores.length === 0 ||
			tableDataHierarchy.length === 0
		)
			return
		setLoading(true)
		let generatedTableData = generateTableData(tableDataHierarchy, filteredStores)
		//let generatedTableData = generateTblData(filteredStores)
		let generatedAverageData = generateAverageData(filteredStores)

		let performanceTotal =
			generatedAverageData.opportunityCountTotal > 0 || generatedAverageData.flavoringCountTotal > 0
				? ((generatedAverageData.flavoringCountTotal / generatedAverageData.opportunityCountTotal) * 100).toFixed(2)
				: 0

		let generatedTableDataStoreCount = generatedTableData.data.reduce((total, item) => {
			return total + item.stores
		}, 0)

		const sortChildren = (data) => {
			if (data.children) {
				data.children.sort((a, b) => {
					const regex = /\d+/

					const numA = parseInt(a.title.match(regex))
					const numB = parseInt(b.title.match(regex))

					if (isNaN(numA) && isNaN(numB)) {
						return 0
					} else if (isNaN(numA)) {
						return 1
					} else if (isNaN(numB)) {
						return -1
					} else {
						return numA - numB
					}
				})
				if (data.children.length > 0) {
					data.children.forEach((child) => {
						sortChildren(child)
					})
				}
			}
		}

		generatedTableData.data.forEach((item) => {
			sortChildren(item)
		})

		const sortedData = generatedTableData.data.sort(({ title: valueA }, { title: valueB }) => {
			const regex = /\d+/

			const numA = parseInt(valueA.match(regex))
			const numB = parseInt(valueB.match(regex))

			if (isNaN(numA) && isNaN(numB)) {
				return 0
			} else if (isNaN(numA)) {
				return 1
			} else if (isNaN(numB)) {
				return -1
			} else {
				return numA - numB
			}
		})

		setStoreCount(generatedTableDataStoreCount)
		setOpportunities(generatedAverageData.opportunityCountTotal)
		setFlavorings(generatedAverageData.flavoringCountTotal)
		setPerformance(performanceTotal)
		setChartData(generatedAverageData.data)
		setTableData(sortedData)
		setCSVData(generatedTableData.csvRowData)

		setLoading(false)
	}, [filteredStores, filteredDateRange, tableDataHierarchy])
	const getRangeSpan = () => {
		let rangeSpan = "day"
		const rangeDif = dayjs(filteredDateRange[1]).diff(filteredDateRange[0], "months", true)
		if (rangeDif > 12) {
			rangeSpan = "year"
		} else if (rangeDif > 2) {
			rangeSpan = "month"
		}
		return rangeSpan
	}
	// Define headers for CSV download
	const headers = [
		{ label: "Title", key: "title" },
		{ label: "Recon", key: "recon" },
		{ label: "Flavor", key: "flavor" },
		{ label: "Recon/Flavor", key: "reconFlavor" },
		{ label: "Opps", key: "opportunities" },
		{ label: "Opps/Store", key: "oppsPerStore" },
		{ label: "Flavorings", key: "flavorings" },
		{ label: "Flavorings/Store", key: "flavoringsPerStore" },
		{ label: "Flavoring %", key: "performance" },
	]

	return (
		<ScreenLayout showSearchBar>
			<Header
				style={{
					background: "#DCDCDC",
					height: 64,
					display: "flex",
					alignItems: "center",
					justifyContent: "center",
				}}
			>
				<Space direction="horizontal">
					<HierarchySelector
						hierarchyLevel={hierarchyLevel}
						hierarchyLevels={initialHierarchy}
						filteredStores={filteredStores}
						filteredDateRange={filteredDateRange}
						onSetHierarchyLevel={setHierarchyLevel}
						onSetHierarchyName={setHierarchyName}
						onSetTableDataHierarchy={setTableDataHierarchy}
						onSetFilteredStores={setFilteredStores}
						storeData={storeData}
					/>

					<RangePicker
						presets={presets}
						defaultValue={filteredDateRange}
						format="MM/DD/YYYY"
						onChange={(dateRange) => setFilteredDateRange(dateRange)}
					/>
				</Space>
			</Header>
			<Layout style={{ margin: 20 }}>
				{!loading && (
					<Row gutter={[24, 24]} wrap={false} justify="end">
						<Col flex="350px">
							<Top5Widget
								title="Performance Averages"
								topPercent={parseInt(performance)}
								topLabel="Flavoring %"
								data={performanceData}
								color="#F5C05B"
							/>

							<Top5Widget
								title="Top 5 Drugs"
								topPercent={topDrugPercent}
								data={topDrugs.slice(0, 5)}
								style={{ marginTop: 20 }}
								color="#679FF6"
								extraText="Of All Scripts"
							/>

							<Top5Widget
								title="Top 5 Flavors"
								topPercent={topFlavorPercent}
								data={topFlavors.slice(0, 5)}
								style={{ marginTop: 20, marginBottom: 20 }}
								color="#E5883B"
								extraText="Of All Flavors"
							/>

							{filteredStores && filteredDateRange && (
								<CSVLink
									data={csvData}
									headers={headers}
									filename={`${hierarchyName}-${dayjs().format("MM-DD-YYYY")}.csv`}
								>
									<Button type="primary" icon={<DownloadOutlined />} style={{ float: "right" }}>
										Download CSV
									</Button>
								</CSVLink>
							)}
						</Col>
						<Col flex="auto">
							{filteredStores && filteredDateRange && (
								<>
									<Card title="Opportunities and Flavorings" size="small">
										<div style={{ float: "left" }}>
											<Text style={{ fontSize: 24 }} strong>
												{opportunities.toLocaleString()}
											</Text>
											<br />
											<div
												style={{
													backgroundColor: "#3F8DA9",
													marginTop: 7,
													marginRight: 3,
													width: 10,
													height: 10,
													borderRadius: 5,
													float: "left",
												}}
											/>
											<Text style={{ fontSize: 12 }}>Opportunities</Text>
										</div>
										<div style={{ float: "left", marginLeft: 30 }}>
											<Text style={{ fontSize: 24 }} strong>
												{flavorings.toLocaleString()}
											</Text>
											<br />

											<div
												style={{
													backgroundColor: "#F5C05B",
													marginTop: 7,
													marginRight: 3,
													width: 10,
													height: 10,
													borderRadius: 5,
													float: "left",
												}}
											/>
											<Text style={{ fontSize: 12 }}>Flavorings</Text>
										</div>
										<div style={{ clear: "both", marginBottom: 10 }}></div>
										<FlavorOppsChart
											data={chartData}
											rangeSpan={getRangeSpan()}
											start={filteredDateRange[0]}
											end={filteredDateRange[1]}
										/>
									</Card>
									<Card title="Average Flavoring" size="small" style={{ marginTop: 20, marginBottom: 20 }}>
										<Text style={{ fontSize: 24, paddingBottom: 10 }} strong>
											{performance}%
										</Text>
										<AvgFlavorChart
											data={chartData}
											rangeSpan={getRangeSpan()}
											start={filteredDateRange[0]}
											end={filteredDateRange[1]}
										/>
									</Card>
								</>
							)}

							{filteredStores && filteredDateRange && (
								<div style={{ backgroundColor: "#fff" }}>
									<Table size="small" columns={columns} dataSource={tableData} pagination={{ pageSize: 15 }} />
								</div>
							)}
						</Col>
					</Row>
				)}
				{loading && <Result title="Processing request, please be patient." />}
			</Layout>
		</ScreenLayout>
	)
}

Dashboard.propTypes = {
	location: PropTypes.object,
}

export default Dashboard
