import { Progress, Typography, Card } from "antd"
import PropTypes from "prop-types"
import React from "react"

const { Text } = Typography
const _ = require("lodash")

const Top5Widget = (props) => {
	return (
		<Card title={props.title} size="small" style={props.style}>
			{props.data.length > 0 && (
				<div
					style={{
						display: "flex",
						flexDirection: "row",
						alignItems: "center",
						justifyContent: "space-between",
					}}
				>
					<div
						style={{
							display: "flex",
							flexDirection: "column",
							alignItems: "center",
						}}
					>
						<Progress
							type="circle"
							strokeWidth={10}
							percent={props.topPercent}
							width={80}
							strokeLinecap="square"
							style={{ margin: 10, color: props.color }}
							strokeColor={props.color}
						/>
						<Text strong>{props.topLabel ? props.topLabel : _.first(props.data).label}</Text>
						{props.extraText && <Text strong>{props.extraText}</Text>}
					</div>
					<div
						style={{
							display: "flex",
							flexDirection: "column",
							fontSize: 13,
							marginBottom: 5,
						}}
					>
						{_.map(props.data, (d, i) => (
							<Text key={i} style={{ paddingRight: 5 }}>
								{d.label}
							</Text>
						))}
					</div>
					<div
						style={{
							display: "flex",
							flexDirection: "column",
							alignItems: "flex-end",
							fontSize: 13,
							marginBottom: 5,
						}}
					>
						{_.map(props.data, (d, i) => (
							<Text strong key={i}>
								{d.value.toLocaleString()}
							</Text>
						))}
					</div>
				</div>
			)}
		</Card>
	)
}
Top5Widget.propTypes = {
	data: PropTypes.any,
	title: PropTypes.sting,
	style: PropTypes.any,
	topPercent: PropTypes.number,
	color: PropTypes.any,
	topLabel: PropTypes.sting,
	extraText: PropTypes.sting,
}

Top5Widget.propTypes = {
	color: PropTypes.string,
	data: PropTypes.array,
	extraText: PropTypes.string,
	style: PropTypes.object,
	title: PropTypes.string,
	topLabel: PropTypes.string,
	topPercent: PropTypes.number,
}

export default Top5Widget
