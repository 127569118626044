import { Space, Checkbox, Select, Card, Button, Layout } from "antd"
import { useStore } from "../stores"
import PropTypes from "prop-types"
import { useObserver } from "mobx-react"
import moment from "moment"

import React, { useEffect, useState } from "react"
import { ScreenLayout, HierarchySelector, SearchBar } from "../components"
import { API } from "../App"
import { CSVLink } from "react-csv"
import "../App.css"
import Title from "antd/es/skeleton/Title"
import { Text } from "recharts"
import { useNavigate } from "react-router"

const { Option } = Select

const Home = () => {
	const navigate = useNavigate()
	const { authStore } = useStore()
	const [filteredStores, setFilteredStores] = useState([])
	const [, setStoreNames] = useState([])
	const [hierarchyFilter, setHierarchyFilter] = useState()
	const [reportType, setReportType] = useState()
	const [addLocationData, setAddLocationData] = useState(false)
	const [csvData, setCSVData] = useState({ rows: [], headers: [] })
	const isAdmin = authStore.isAdmin

	useEffect(() => {
		API.getStoreNames().then((res) => {
			setStoreNames(res.data.filter((d) => d !== null && d !== ""))
		})
	}, [])

	const generateReport = () => {
		setCSVData({ rows: [], headers: [] })

		API.getFilteredStores({ ...hierarchyFilter }).then((res) => {
			let headers = [
				{ label: "Customer", key: "hierarchyLevel1" },
				{ label: "Location ID", key: "location_id" },
				{ label: "Customer Number", key: "customer_number" },
				{ label: "Store Number", key: "store_number" },
			]

			if (addLocationData) {
				headers.push(
					...[
						{ label: "Address", key: "address1" },
						{ label: "Address 2", key: "address2" },
						{ label: "City", key: "city" },
						{ label: "State", key: "state" },
						{ label: "Zip", key: "zip" },
					]
				)
			}

			let rows = []
			if (reportType === "Dispenser") {
				headers.push(
					{ label: "Serial #", key: "dispenser_serial_number" },
					{ label: "Type", key: "dispenser_type" },
					{ label: "Model #", key: "dispenser_model_number" },
					{ label: "MAC Address", key: "dispenser_mac_address" },
					{ label: "Firmware", key: "dispenser_firmware" },
					{ label: "Database", key: "dispenser_database" },
					{ label: "Last Transmission Date", key: "last_transmission" },
					{ label: "Last Transmission Time", key: "last_transmission_time" },
					{ label: "Last Sanitization Prompt Date", key: "last_sanitization_prompt" },
					{ label: "Last Sanitization Prompt Time", key: "last_sanitization_prompt_time" },
					{ label: "Last Sanitization Date", key: "last_sanitization_complete" },
					{ label: "Last Sanitization Time", key: "last_sanitization_complete_time" },
					{ label: "Last Water Calibration Date", key: "last_water_calibration" },
					{ label: "Last Water Calibration Time", key: "last_water_calibration_time" }
				)
				rows = res.data.map((d) => {
					let rowData = {
						hierarchyLevel1: d.hierarchyLevel1,
						portal_number: d.portal_number,
						location_id: d.location_id,
						store_number: d.store_number,
						customer_number: d.customer_number,
					}
					if (d.dispenser_snapshot) {
						rowData.dispenser_type = d.dispenser_snapshot?.dispenser_type
						rowData.dispenser_model_number = d.dispenser_snapshot?.dispenser_model_number
						rowData.dispenser_serial_number = d.dispenser_snapshot?.dispenser_serial_number
						rowData.dispenser_mac_address = d.dispenser_snapshot?.dispenser_mac_address
						rowData.dispenser_firmware = d.dispenser_snapshot?.dispenser_firmware
						rowData.dispenser_database = d.dispenser_snapshot?.dispenser_database
						rowData.last_transmission = d.dispenser_snapshot.last_transmission
							? moment.utc(d.dispenser_snapshot?.last_transmission).format("MM/DD/YYYY")
							: null
						rowData.last_sanitization_prompt = d.dispenser_snapshot.last_sanitization_prompt
							? moment.utc(d.dispenser_snapshot?.last_sanitization_prompt).format("MM/DD/YYYY")
							: null
						rowData.last_sanitization_complete = d.dispenser_snapshot.last_sanitization_complete
							? moment.utc(d.dispenser_snapshot?.last_sanitization_complete).format("MM/DD/YYYY")
							: null
						rowData.last_water_calibration = d.dispenser_snapshot.last_water_calibration
							? moment.utc(d.dispenser_snapshot?.last_water_calibration).format("MM/DD/YYYY")
							: null
						rowData.last_transmission_time = d.dispenser_snapshot.last_transmission
							? moment.utc(d.dispenser_snapshot?.last_transmission).format("HH:mm:SS")
							: null
						rowData.last_sanitization_prompt_time = d.dispenser_snapshot.last_sanitization_prompt
							? moment.utc(d.dispenser_snapshot?.last_sanitization_prompt).format("HH:mm:SS")
							: null
						rowData.last_sanitization_complete_time = d.dispenser_snapshot.last_sanitization_complete
							? moment.utc(d.dispenser_snapshot?.last_sanitization_complete).format("HH:mm:SS")
							: null
						rowData.last_water_calibration_time = d.dispenser_snapshot.last_water_calibration
							? moment.utc(d.dispenser_snapshot?.last_water_calibration).format("HH:mm:SS")
							: null
					}
					if (addLocationData) {
						rowData.address1 = d.address1
						rowData.address2 = d.address2
						rowData.city = d.city
						rowData.state = d.state
						rowData.zip = d.zip
					}
					return rowData
				})
			} else if (reportType === "Flavor") {
				headers.push(
					{ label: "Flavor", key: "flavor" },
					{ label: "Flavor Volume %", key: "level" },
					{ label: "Exp", key: "exp_date" },
					{ label: "Lot Number", key: "lot_num" }
				)
				rows = res.data
					.map((store) => {
						let storeData = {
							hierarchyLevel1: store.hierarchyLevel1,
							portal_number: store.portal_number,
							location_id: store.location_id,
							store_number: store.store_number,
							customer_number: store.customer_number,
						}
						if (addLocationData) {
							storeData.address1 = store.address1
							storeData.address2 = store.address2
							storeData.city = store.city
							storeData.state = store.state
							storeData.zip = store.zip
						}

						if (!store.dispenser_snapshot?.flavors) return { ...storeData }
						else
							return store.dispenser_snapshot?.flavors?.map((d) => {
								return { ...d, ...storeData }
							})
					})
					.flat()
			}
			console.log(rows)

			setCSVData({ rows, headers })
		})
	}

	return (
		<ScreenLayout>
			<Layout style={{ margin: 20 }}>
				<Card style={{ width: 1200, margin: "20px auto" }}>
					<Title level={4}>Flavoring Program Analytics</Title>
					<SearchBar />
					{isAdmin && (
						<Button style={{ float: "right", marginTop: 5 }} onClick={() => navigate(`/editstore`)}>
							Add New Store
						</Button>
					)}
				</Card>

				<Card style={{ width: 1200, margin: "20px auto" }}>
					<Title level={4}>Reports</Title>
					<Text>Select Customer</Text>
					<br />
					<HierarchySelector
						hierarchyLevel={0}
						filteredStores={filteredStores}
						onSetFilteredStores={setFilteredStores}
						onSetHierarchyFilter={setHierarchyFilter}
						blockNavigation
					/>
					<br />
					<br />
					<Text>Report Type</Text>
					<br />
					<Select placeholder="Select" onChange={(val) => setReportType(val)} style={{ width: 200 }}>
						<Option value="Dispenser">Dispenser Information</Option>
						<Option value="Flavor">Flavor Levels</Option>
					</Select>
					<br />
					<br />
					<Checkbox onChange={(val) => setAddLocationData(val)}>Include Store Address Data</Checkbox>
					<br />
					<br />
					<Space>
						<Button onClick={() => generateReport()}>Generate Report</Button>

						{csvData.rows.length > 0 && (
							<CSVLink data={csvData.rows} headers={csvData.headers} filename={`${moment().format("MM-DD-YY")}.csv`}>
								<Button>Download Report</Button>
							</CSVLink>
						)}
					</Space>
				</Card>
			</Layout>
		</ScreenLayout>
	)
}

Home.propTypes = {
	history: PropTypes.object,
}

export default Home
