import { Layout } from "antd"
import PropTypes from "prop-types"
import { observer } from "mobx-react"
import React from "react"
import { useNavigate } from "react-router-dom"
import { useStore } from "../../stores"
import { UserOutlined } from "@ant-design/icons"
import { HeaderButton, UserMenu, UserRoleTag, SearchBar } from "../"
import { LogoutOutlined, SettingOutlined } from "@ant-design/icons"
const { Content, Footer, Header } = Layout

export const ScreenLayout = observer(({ children, showSearchBar, hideCustomerSearch }) => {
	const { authStore } = useStore()
	const navigate = useNavigate()
	const user = authStore.user
	const onLogoutClick = () => {
		authStore.logout()
		navigate("/login")
	}

	const onUserManagementClick = () => {
		navigate("/user-management")
	}

	const LogOutItem = {
		label: (
			<HeaderButton onClick={onLogoutClick} text={"Log Out"} icon={<LogoutOutlined style={{ marginRight: 10 }} />} />
		),
		key: "logout",
	}

	const UserManagementItem = {
		label: (
			<HeaderButton
				onClick={onUserManagementClick}
				text={"User Management"}
				icon={<SettingOutlined style={{ marginRight: 10 }} />}
			/>
		),
		key: "user-management",
	}

	const items = []

	if (authStore.isAdmin && authStore.authenticated) {
		items.push(UserManagementItem)
	}

	if (authStore.authenticated) {
		items.push(LogOutItem)
	}

	return (
		<Layout style={{ height: "100vh" }}>
			<Header
				style={{
					maxHeight: 75,
					background: "white",
					display: "flex",
					justifyContent: "space-between",
					alignItems: "center",
				}}
			>
				<img onClick={() => navigate("/")} src={require("../../logo.png")} height={50} alt="Logo" />
				{showSearchBar && <SearchBar hideRangePicker hideCustomerSearch={hideCustomerSearch} />}
				{user && (
					<div style={{ display: "flex", alignItems: "center" }}>
						<div>
							{user.roles.map(({ name }, index) => (
								<UserRoleTag key={index} role={name} />
							))}
						</div>
						<UserOutlined style={{ marginRight: 5 }} />
						<UserMenu userName={user.name} items={items} />
					</div>
				)}
			</Header>
			<Layout>
				<Content>{children}</Content>
				<Footer style={{ textAlign: "center" }}></Footer>
			</Layout>
		</Layout>
	)
})

ScreenLayout.propTypes = {
	children: PropTypes.node.isRequired,
	showSearchBar: PropTypes.bool,
	hideCustomerSearch: PropTypes.bool,
}

export default ScreenLayout
