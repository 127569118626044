import { createContext, useContext } from "react"
import DataStore from "./DataStore"
import AuthStore from "./AuthStore"

export const store = {
	dataStore: new DataStore(),
	authStore: new AuthStore(),
}

export const StoreContext = createContext(store)

export const useStore = () => {
	return useContext(StoreContext)
}
