import { Card, Col, Layout, Form, Select, Input, Typography, Row, Divider, Button, Space, Modal } from "antd"
import { ExclamationCircleOutlined, CheckCircleOutlined } from "@ant-design/icons"
import { inject, observer } from "mobx-react"
import React, { useEffect, useState } from "react"
import { API } from "../App"
import { ScreenLayout } from "../components"
import PropTypes from "prop-types"
import { useNavigate, useParams } from "react-router"

const { Text, Title } = Typography
const { Option } = Select

const EditStore = (props) => {
	const navigate = useNavigate()
	const [store, setStore] = useState()
	const [confirmDelete, setConfirmDelete] = useState(false)
	const [storeAdded, setStoreAdded] = useState(false)
	const [storeDeleted, setStoreDeleted] = useState(false)
	const [storeSaved, setStoreSaved] = useState(false)
	const [newStoreID, setNewStoreID] = useState()
	const { storeID } = useParams()
	const [form] = Form.useForm()
	const formItemLayout = {
		labelCol: {
			xs: { span: 24 },
			sm: { span: 8 },
		},
		wrapperCol: {
			xs: { span: 24 },
			sm: { span: 16 },
		},
	}

	useEffect(() => {
		if (storeID) {
			API.getStore({ where: { id: storeID } }).then((res) => {
				if (res.data.length > 0) setStore(res.data[0])
			})
		}
	}, [])

	useEffect(() => form.resetFields(), [store])

	return (
		<ScreenLayout>
			<Layout style={{ margin: 20 }}>
				{!storeAdded && !storeDeleted && !storeSaved && (
					<Card title={storeID ? "Edit Store" : "Add Store"} style={{ width: 1000, margin: "0 auto" }}>
						<Form
							form={form}
							name="advanced_search"
							className="ant-advanced-search-form"
							onFinish={(values) => {
								if (store) {
									API.updateStore(store.id, values).then(() => {
										setStoreSaved(true)
									})
								} else {
									let data = { ...values }
									if (!data.hierarchyLevel2) data.hierarchyLevel2 = ""
									if (!data.hierarchyLevel3) data.hierarchyLevel3 = ""
									if (!data.hierarchyLevel4) data.hierarchyLevel4 = ""
									if (!data.hierarchyLevel5) data.hierarchyLevel5 = ""
									API.addStore(data).then((res) => {
										setNewStoreID(res.data.id)
										setStoreAdded(true)
									})
								}
							}}
							initialValues={{ ...store }}
							{...formItemLayout}
						>
							<Text strong style={{ paddingBottom: 20, display: "flex" }}>
								Customer Information
							</Text>
							<Row gutter={24}>
								<Col
									span={12}
									style={{
										textAlign: "right",
									}}
								>
									<Form.Item label="Customer Name" name="hierarchyLevel1">
										<Input />
									</Form.Item>
									<Form.Item label="Location ID" name="location_id">
										<Input />
									</Form.Item>
									<Form.Item label="Portal Name" name="portal_name">
										<Input />
									</Form.Item>
									<Form.Item label="Customer Number" name="customer_number">
										<Input />
									</Form.Item>
									<Form.Item label="Store Number" name="store_number">
										<Input />
									</Form.Item>
									<Form.Item label="Store Name" name="store_name">
										<Input />
									</Form.Item>
								</Col>
								<Col span={12}>
									<Form.Item label="Address1" name="address1">
										<Input />
									</Form.Item>
									<Form.Item label="Address2" name="address2">
										<Input />
									</Form.Item>
									<Form.Item label="City" name="city">
										<Input />
									</Form.Item>
									<Form.Item label="State" name="state">
										<Select placeholder="Select State">
											<Option value="AL">Alabama</Option>
											<Option value="AK">Alaska</Option>
											<Option value="AZ">Arizona</Option>
											<Option value="AR">Arkansas</Option>
											<Option value="CA">California</Option>
											<Option value="CO">Colorado</Option>
											<Option value="CT">Connecticut</Option>
											<Option value="DE">Delaware</Option>
											<Option value="DC">District Of Columbia</Option>
											<Option value="FL">Florida</Option>
											<Option value="GA">Georgia</Option>
											<Option value="HI">Hawaii</Option>
											<Option value="ID">Idaho</Option>
											<Option value="IL">Illinois</Option>
											<Option value="IN">Indiana</Option>
											<Option value="IA">Iowa</Option>
											<Option value="KS">Kansas</Option>
											<Option value="KY">Kentucky</Option>
											<Option value="LA">Louisiana</Option>
											<Option value="ME">Maine</Option>
											<Option value="MD">Maryland</Option>
											<Option value="MA">Massachusetts</Option>
											<Option value="MI">Michigan</Option>
											<Option value="MN">Minnesota</Option>
											<Option value="MS">Mississippi</Option>
											<Option value="MO">Missouri</Option>
											<Option value="MT">Montana</Option>
											<Option value="NE">Nebraska</Option>
											<Option value="NV">Nevada</Option>
											<Option value="NH">New Hampshire</Option>
											<Option value="NJ">New Jersey</Option>
											<Option value="NM">New Mexico</Option>
											<Option value="NY">New York</Option>
											<Option value="NC">North Carolina</Option>
											<Option value="ND">North Dakota</Option>
											<Option value="OH">Ohio</Option>
											<Option value="OK">Oklahoma</Option>
											<Option value="OR">Oregon</Option>
											<Option value="PA">Pennsylvania</Option>
											<Option value="RI">Rhode Island</Option>
											<Option value="SC">South Carolina</Option>
											<Option value="SD">South Dakota</Option>
											<Option value="TN">Tennessee</Option>
											<Option value="TX">Texas</Option>
											<Option value="UT">Utah</Option>
											<Option value="VT">Vermont</Option>
											<Option value="VA">Virginia</Option>
											<Option value="WA">Washington</Option>
											<Option value="WV">West Virginia</Option>
											<Option value="WI">Wisconsin</Option>
											<Option value="WY">Wyoming</Option>
										</Select>
									</Form.Item>
									<Form.Item label="Zip" name="zip">
										<Input />
									</Form.Item>
								</Col>
							</Row>
							<Divider />
							<Text strong style={{ paddingBottom: 20, display: "flex" }}>
								Customer Hierarchy
							</Text>
							<Row gutter={24}>
								<Col
									span={12}
									style={{
										textAlign: "right",
									}}
								>
									<Form.Item label="Hierarchy Level 2" name="hierarchyLevel2">
										<Input />
									</Form.Item>
									<Form.Item label="Hierarchy Level 3" name="hierarchyLevel3">
										<Input />
									</Form.Item>
									<Form.Item label="Hierarchy Level 4" name="hierarchyLevel4">
										<Input />
									</Form.Item>
								</Col>
							</Row>
							<Form.Item wrapperCol={{ offset: 4 }}>
								<Space>
									<Button type="primary" htmlType="submit">
										Save
									</Button>
									<Button onClick={() => props.history.goBack()}>Cancel</Button>
								</Space>
								{store && (
									<Button style={{ float: "right" }} onClick={() => setConfirmDelete(true)} danger>
										Delete Store
									</Button>
								)}
							</Form.Item>
						</Form>
					</Card>
				)}
				{storeAdded && (
					<Card style={{ width: 1000, margin: "0 auto" }}>
						<div style={{ textAlign: "center" }}>
							<CheckCircleOutlined style={{ fontSize: "36px", color: "#57BE43", marginBottom: 20 }} />
							<Title level={5}>Saved: New Store Added</Title>
							<br />
							<Space>
								<Button onClick={() => navigate({ pathname: `/store/${newStoreID}` })}>Go To Store</Button>
								<Button
									onClick={() => {
										setStoreAdded(false)
										form.resetFields()
									}}
								>
									Add Another Store
								</Button>
								<Button onClick={() => navigate({ pathname: `/` })}>Back To Home</Button>
							</Space>
						</div>
					</Card>
				)}
				{storeDeleted && (
					<Card style={{ width: 1000, margin: "0 auto" }}>
						<div style={{ textAlign: "center" }}>
							<CheckCircleOutlined style={{ fontSize: "36px", color: "#57BE43", marginBottom: 20 }} />
							<Title level={5}>Deleted:This Store Has Been Removed</Title>
							<br />
							<Space>
								<Button onClick={() => navigate({ pathname: `/` })}>Back To Home</Button>
							</Space>
						</div>
					</Card>
				)}
				{storeSaved && (
					<Card style={{ width: 1000, margin: "0 auto" }}>
						<div style={{ textAlign: "center" }}>
							<CheckCircleOutlined style={{ fontSize: "36px", color: "#57BE43", marginBottom: 20 }} />
							<Title level={5}>Saved: New Information Updated</Title>
							<br />
							<Space>
								<Button onClick={() => navigate({ pathname: `/store/${storeID}` })}>Go To Store</Button>
								<Button onClick={() => navigate({ pathname: `/` })}>Back To Home</Button>
							</Space>
						</div>
					</Card>
				)}
				<Modal open={confirmDelete} footer={null} closable={false}>
					<div style={{ textAlign: "center" }}>
						<ExclamationCircleOutlined style={{ fontSize: "36px", color: "#ED732E", marginBottom: 20 }} />
						<Title level={5}>Are you sure you want to delete this store?</Title>
						<br />
						<Space>
							<Button
								type="primary"
								onClick={() => {
									API.deleteStore(storeID).then(() => {
										setConfirmDelete(false)
										setStoreDeleted(true)
									})
								}}
							>
								Yes, Delete
							</Button>
							<Button onClick={() => setConfirmDelete(false)}>Cancel</Button>
						</Space>
					</div>
				</Modal>
			</Layout>
		</ScreenLayout>
	)
}

EditStore.propTypes = {
	history: PropTypes.object,
	match: PropTypes.object,
}

export default inject("DataStore")(observer(EditStore))
