import React, { useState, useEffect } from "react"
import { ScreenLayout, UserManagementTable, UserManagementHeader, InviteUser } from "../components"
import { Divider, Card } from "antd"
import { API } from "../App"

const UserManagementScreen = () => {
	const [users, setUsers] = useState([])
	useEffect(() => {
		const fetchUsers = async () => {
			const { data } = await API.getUsers()
			setUsers(data)
		}
		fetchUsers()
	}, [])

	return (
		<ScreenLayout>
			<Card
				title="User Management"
				extra={<InviteUser setUsers={setUsers} />}
				style={{ maxWidth: 1200, margin: "auto", marginTop: 40 }}
			>
				<UserManagementTable users={users} setUsers={setUsers} />
			</Card>
		</ScreenLayout>
	)
}

export default UserManagementScreen
