import React from "react"
import { RouterProvider } from "react-router-dom"
import router from "./router"
import { StoreContext, store } from "./stores"
import RestAPI from "./utils/API"

function App() {
	return (
		<StoreContext.Provider value={store}>
			<RouterProvider router={router} />
		</StoreContext.Provider>
	)
}

export default App

export const API = RestAPI.setup()
