import { Button } from "antd"
import React, { useState } from "react"
import { InviteUserModal } from "./"
import PropTypes from "prop-types"

const InviteUser = ({ setUsers }) => {
	const [isModalOpen, setIsModalOpen] = useState(false)

	const showModal = () => {
		setIsModalOpen(true)
	}

	const hideModal = () => {
		setIsModalOpen(false)
	}

	const handleCancel = () => {
		hideModal()
	}

	return (
		<div>
			<Button type="primary" onClick={showModal}>
				Invite User
			</Button>
			<InviteUserModal isModalOpen={isModalOpen} handleCancel={handleCancel} setUsers={setUsers} />
		</div>
	)
}

InviteUser.propTypes = {
	setUsers: PropTypes.func.isRequired,
}

export default InviteUser
