import { Space, Select, AutoComplete } from "antd"
import PropTypes from "prop-types"
import React, { useState, useEffect } from "react"
import { API } from "../../App"
import { useNavigate } from "react-router-dom"

const { Option } = Select
const _ = require("lodash")

const HierarchySelector = (props) => {
	const navigate = useNavigate()
	const [storeHierarchy, setStoreHierarchy] = useState([])
	const [storeNames, setStoreNames] = useState([])
	const [hierarchyLevel, setHierarchyLevel] = useState(props.hierarchyLevel || 0)
	const [hierarchyLevels, setHierarchyLevels] = useState(props.hierarchyLevels || {})
	const [filteredStores, setFilteredStores] = useState(props.filteredStores || [])
	const [storeData, setStoreData] = useState(props.storeData || [])
	const [options, setOptions] = useState([])
	const [selectedHierarchyLevel1, setSelectedHierarchyLevel1] = useState()

	useEffect(() => {
		API.getStoreNames().then((res) => {
			const filteredData = res.data
				.map((d) => ({
					value: d,
				}))
				.filter((d) => d.value)
			setStoreNames(filteredData)
			setOptions(filteredData)
		})

		if (hierarchyLevels.hierarchyLevel1 && !selectedHierarchyLevel1)
			setSelectedHierarchyLevel1(hierarchyLevels.hierarchyLevel1)
	}, [])

	useEffect(() => {
		if (selectedHierarchyLevel1) {
			setHierarchyLevel(0)
			API.getFilteredSnapshots({ where: { hierarchyLevel1: { eq: selectedHierarchyLevel1 } } }).then((res) => {
				setHierarchyLevel(1)
				setStoreHierarchy(res.data)
			})
			setHierarchyLevels({ hierarchyLevel1: selectedHierarchyLevel1 })
			if (props.onSetHierarchyFilter)
				props.onSetHierarchyFilter({ where: { hierarchyLevel1: selectedHierarchyLevel1 } })
		}
	}, [selectedHierarchyLevel1])

	const buildHierarchy = () => {
		let parentHierarchy = filteredStores[0].hierarchyLevel1
		let results = []
		let lev2 = _.groupBy(filteredStores, (d) => `${d.hierarchyLevel2}`)

		_.forEach(lev2, function (value, key) {
			results.push({
				value: key,
				level: "hierarchyLevel2",
				hierarchy: `${parentHierarchy}|${key}`,
				children: buildChildHierarchy(3, value, `${parentHierarchy}|${key}`),
			})
		})

		return results
	}
	const buildChildHierarchy = (level, values, parentHierarchy) => {
		const levs = _.groupBy(values, (d) => d[`hierarchyLevel${level}`])
		const results = []
		_.forEach(levs, function (value, key) {
			if (key == "") {
				//get the stores
				value.forEach((a) => {
					a.stores.forEach((b) => {
						results.push({
							value: b.name,
							storeId: b.id,
							level: `hierarchyLevelStore`,
						})
					})
				})
			} else {
				let obj = {
					value: key,
					level: `hierarchyLevel${level}`,
					hierarchy: `${parentHierarchy}|${key}`,
				}
				if (level < 5) {
					let children = buildChildHierarchy(level + 1, value, `${parentHierarchy}|${key}`)
					if (children !== null || children.length > 0) obj.children = children
				}
				results.push(obj)
			}
		})

		return results
	}
	useEffect(() => {
		if (!filteredStores || hierarchyLevel === 0 || filteredStores.length === 0) return

		if (props.onSetFilteredStores) props.onSetFilteredStores(filteredStores)
		if (props.onSetTableDataHierarchy) {
			props.onSetTableDataHierarchy(buildHierarchy())
		}
	}, [filteredStores, hierarchyLevel])

	useEffect(() => {
		if (props.onSetHierarchyLevel) props.onSetHierarchyLevel(hierarchyLevel)
	}, [hierarchyLevel])

	useEffect(() => {
		if (!props.onSetHierarchyName) return
		let levels = Object.values(hierarchyLevels)
		if (levels.length === 1) props.onSetHierarchyName(_.head(levels))
		else if (levels.length > 1) props.onSetHierarchyName(`${_.head(levels)}-${_.last(levels)}`)
	}, [hierarchyLevels])

	useEffect(() => {
		if (filteredStores.length == 0 && props.filteredStores.length > 0) setFilteredStores(props.filteredStores)
	}, [props.filteredStores])
	useEffect(() => {
		if (storeData.length == 0 && props.storeData && props.storeData.length > 0) setStoreData(props.storeData)
	}, [props.storeData])
	return (
		<Space direction="horizontal">
			<AutoComplete
				options={options}
				style={{ width: 200 }}
				placeholder="Search"
				value={selectedHierarchyLevel1}
				onSelect={(val) => setSelectedHierarchyLevel1(val)}
				onSearch={(val) => {
					let data = storeNames.filter((d) => d.value.toLowerCase().includes(val?.toLowerCase()))
					setOptions(data)
				}}
			/>
			{[...Array(5).keys()].map((i) => {
				if (i <= hierarchyLevel && i > 0) {
					let storeHierarchyLevel = false

					// Get hierarchy objects
					const filteredHierarchyObjects = _.sortBy(
						_.unionBy(storeHierarchy, `hierarchyLevel${i + 1}`),
						`hierarchyLevel${i + 1}`
					).map((d) => {
						if (d[`hierarchyLevel${i + 1}`])
							return { val: d[`hierarchyLevel${i + 1}`], label: d[`hierarchyLevel${i + 1}`] }
					})

					// Get parent hierarchy snapshot to add any stores to the options
					const parentHierarchyObject = storeHierarchy.find((d) => {
						let match = d.hierarchyLevel1 === hierarchyLevels.hierarchyLevel1

						if (i === 1 && match) {
							return (
								d.hierarchyLevel2 == "" && d.hierarchyLevel3 == "" && d.hierarchyLevel4 == "" && d.hierarchyLevel5 == ""
							)
						} else if (i == 2 && match) {
							return d.hierarchyLevel2 == hierarchyLevels.hierarchyLevel2 && d.hierarchyLevel3 == ""
						} else if (i == 3 && match) {
							return (
								d.hierarchyLevel2 == hierarchyLevels.hierarchyLevel2 &&
								d.hierarchyLevel3 == hierarchyLevels.hierarchyLevel3 &&
								d.hierarchyLevel4 == ""
							)
						} else if (i == 4 && match) {
							return (
								d.hierarchyLevel2 == hierarchyLevels.hierarchyLevel2 &&
								d.hierarchyLevel3 == hierarchyLevels.hierarchyLevel3 &&
								d.hierarchyLevel4 == hierarchyLevels.hierarchyLevel4 &&
								d.hierarchyLevel5 == ""
							)
						} else if (i == 5 && match) {
							return (
								d.hierarchyLevel2 == hierarchyLevels.hierarchyLevel2 &&
								d.hierarchyLevel3 == hierarchyLevels.hierarchyLevel3 &&
								d.hierarchyLevel4 == hierarchyLevels.hierarchyLevel4 &&
								d.hierarchyLevel5 == hierarchyLevels.hierarchyLevel5
							)
						}
					})

					let options = parentHierarchyObject?.stores
						? [
								...filteredHierarchyObjects,
								...parentHierarchyObject?.stores.map((d) => ({ val: `|STORE|${d.id}`, label: d.name })),
						  ]
						: filteredHierarchyObjects

					return (
						<Select
							key={i}
							showSearch
							placeholder={storeHierarchyLevel ? "Select Store" : "Select"}
							defaultValue={hierarchyLevels?.[`hierarchyLevel${i + 1}`]}
							onChange={(val) => {
								if (val.includes("|STORE|")) {
									storeHierarchyLevel = true
									val = val.replace("|STORE|", "")
								} else {
									storeHierarchyLevel = false
								}

								if (storeHierarchyLevel && !props.blockNavigation) {
									if (props.filteredDateRange)
										navigate(
											`/store?dispenserID=${val}&dateRangeStart=${props.filteredDateRange[0].format()}&dateRangeEnd=${props.filteredDateRange[1].format()}`
										)
									else navigate(`/store/${val}`)
								} else if (storeHierarchyLevel && props.blockNavigation) {
									if (props.onSetHierarchyFilter) props.onSetHierarchyFilter({ where: { id: val } })
									API.getFilteredSnapshots({ where: { id: val } }).then((res) => setFilteredStores(res.data))
								} else {
									let hierarchyObj = { ...hierarchyLevels }
									hierarchyObj[`hierarchyLevel${i + 1}`] = val
									let filters = []
									for (let j = 0; j <= i; j++) {
										let filter = {}
										filter[`hierarchyLevel${j + 1}`] = hierarchyObj[`hierarchyLevel${j + 1}`]
										filters.push(filter)
									}
									setHierarchyLevel(i + 1)
									setHierarchyLevels(hierarchyObj)
									if (props.onSetHierarchyFilter) props.onSetHierarchyFilter({ where: { and: filters } })
									API.getFilteredSnapshots({ where: { and: filters } }).then((res) => setFilteredStores(res.data))
								}
							}}
							filterOption={(input, option) => option.children?.toLowerCase().includes(input.toLowerCase())}
							style={{ width: 200 }}
						>
							{_.uniqBy(options, "val")
								.sort(({ label: valueA }, { label: valueB }) => {
									const regex = /\d+/

									const numA = parseInt(valueA.match(regex))
									const numB = parseInt(valueB.match(regex))

									if (isNaN(numA) && isNaN(numB)) {
										return valueA.localeCompare(valueB)
									} else if (isNaN(numA)) {
										return 1
									} else if (isNaN(numB)) {
										return -1
									} else {
										return numA - numB
									}
								})

								.map((d) => {
									if (d) {
										d.label = d.label.replace(/\b0+(\d+)/g, "$1")
										return (
											<Option key={d.id} value={d.val}>
												{d.label}
											</Option>
										)
									}
								})}
						</Select>
					)
				} else return
			})}
		</Space>
	)
}

HierarchySelector.propTypes = {
	onSetHierarchyFilter: PropTypes.func,
	filteredDateRange: PropTypes.array,
	blockNavigation: PropTypes.bool,
	filteredStores: PropTypes.array,
	storeData: PropTypes.array,
	hierarchyLevel: PropTypes.number,
	hierarchyLevels: PropTypes.object,
	onSetFilteredStores: PropTypes.func,
	onSetTableDataHierarchy: PropTypes.func,
	onSetHierarchyLevel: PropTypes.func,
	onSetHierarchyName: PropTypes.func,
}

export default HierarchySelector
